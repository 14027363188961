import React from 'react';
import './Footer.css';

// payment method logos
import skrillLogo from '../../../assets/images/logos/payment/skrill.png';
import netellerLogo from '../../../assets/images/logos/payment/neteller.png';
import bitcoinLogo from '../../../assets/images/logos/payment/bitcoin.png';
import paytriotLogo from '../../../assets/images/logos/payment/paytriot_cards.png';

import Logo from '../../Logo';
import FooterLinks from './FooterLinks';
import Copyright from './Copyright';
import DepositModal from "./DepositModal";
import SignupNowModal from "./SignupNowModal";
import TemporarilyUnavailableModal from "./TemporarilyUnavailableModal";

const Footer: React.FC = () => {
  return (
    <footer className="bd-footer text-muted container mb-4">
      <div className="container text-center">
        <div className="row mb-5 h-25"/>
      </div>
      <Logo className="d-inline-block mx-auto d-block align-top" />
      <br/>
      <FooterLinks />
      <div className="row text-center payment-logos align-items-center">
        <div className="col-md-3 mt-2 mb-2">
          <img src={skrillLogo} className="skrill-logo" alt="Skrill" />
        </div>
        <div className="col-md-3 mt-2 mb-2">
          <img src={netellerLogo} alt="Neteller" />
        </div>
        <div className="col-md-3 mt-2 mb-2">
          <img src={bitcoinLogo} alt="Bitcoin" />
        </div>
        <div className="col-md-3 mt-2 mb-2 paytriot-logo">
          <img src={paytriotLogo} alt="Paytriot Payments" />
        </div>
      </div>
      <Copyright />
      <DepositModal />
      <SignupNowModal />
      <TemporarilyUnavailableModal />
    </footer>
  );
};

export default Footer;