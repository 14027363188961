import React, {MouseEvent} from 'react';
import {Link} from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';

import {faDice, faUser, faMoneyBillWave, faFile, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {useTranslation} from '../../../../utils/hooks';
import {useAuth} from "../../../../support/player";

import {
  TAB_ID_PERSONAL_DETAILS,
  TAB_ID_PERSONAL_DOCUMENTS,
  TAB_ID_PAYMENT_DETAILS
} from "../../../../routes/Profile/support/constants";

import AccountMenuDropdown from './AccountMenuDropdown';
import AccountMenuDropdownToggle from './AccountMenuDropdown/AccountMenuDropdownToggle';
import AccountMenuDropdownMenu from './AccountMenuDropdown/AccountMenuDropdownMenu';
import AccountMenuDropdownMenuPlayerDetails from "./AccountMenuDropdown/AccountMenuDropdownMenu/AccountMenuDropdownMenuPlayerDetails";

import GameLink from '../../../GameLink';

export default () => {
  const {t} = useTranslation();
  const {logout} = useAuth();

  return (
    <Dropdown as={AccountMenuDropdown}>
      <Dropdown.Toggle as={AccountMenuDropdownToggle} id="account-menu-dropdown"/>
      <Dropdown.Menu as={AccountMenuDropdownMenu}>
        <AccountMenuDropdownMenuPlayerDetails/>
        <Dropdown.Item
          as={GameLink}
          className="font-weight-bold"
          to="/game"
        >
          <FontAwesomeIcon className="menu-icon" icon={faDice} />
          {t('navigation.links.playNow', 'Play now')}
        </Dropdown.Item>
        <br/><br/>
        <Dropdown.Item
          as={Link}
          className="font-weight-bold"
          to={`/profile?tab=${TAB_ID_PERSONAL_DETAILS}`}
        >
          <FontAwesomeIcon className="menu-icon" icon={faUser} />
          {t('navigation.links.personalDetails', 'Personal details')}
        </Dropdown.Item>
        <br/><br/>
        <Dropdown.Item
          as={Link}
          className="font-weight-bold"
          to={`/profile?tab=${TAB_ID_PAYMENT_DETAILS}`}
        >
          <FontAwesomeIcon className="menu-icon" icon={faMoneyBillWave} />
          {t('navigation.links.paymentDetails', 'Payments details')}
        </Dropdown.Item>
        <br/><br/>
        <Dropdown.Item
          as={Link}
          className="font-weight-bold"
          to={`/profile?tab=${TAB_ID_PERSONAL_DOCUMENTS}`}
        >
          <FontAwesomeIcon className="menu-icon" icon={faFile} />
          {t('navigation.links.personalDocuments', 'Personal documents')}
        </Dropdown.Item>
        <br/><br/>
        <Dropdown.Item
          className="font-weight-bold"
          onClick={async (e: MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();

            await logout();
          }}>
          <FontAwesomeIcon className="menu-icon" icon={faSignOutAlt} />
          {t('navigation.links.logout', 'Logout')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};