import React, {MouseEvent} from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import {faDonate} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import AppContext from "../../../../../../../utils/app-state/app-context";
import {useTranslation} from "react-i18next";
import {formatMoney} from "../../../../../../../support/helpers";

const AccountMenuDropdownMenuPlayerDetails: React.FC = () => {
  const { t } = useTranslation();
  const {player, dispatchAppState} = React.useContext(AppContext);

  return (
    <>
      <div className="account-details">
        <p className="mb-2 text-gold font-weight-bold">
          {player.data.firstName} {player.data.lastName}
        </p>
        <p className="balance text-white">
          {t('navigation.links.balance', 'Balance')} : € {formatMoney(player.data.balance || 0)}
        </p>
      </div>
      <div className="dropdown-item-deposit">
        <Dropdown.Item
          className="font-weight-bold"
          onClick={(e: MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();

            dispatchAppState({ type: 'SHOW_DEPOSIT_MODAL' });
          }}
        >
          <FontAwesomeIcon className="menu-icon" icon={faDonate} />
          {t('navigation.links.deposit', 'Deposit')}
        </Dropdown.Item>
        <br /><br />
      </div>
    </>
  )
};

export default AccountMenuDropdownMenuPlayerDetails;