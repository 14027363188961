import React, {HTMLAttributes} from 'react';

import './AccountMenuDropdownMenu.css';

import DropdownMenu, {DropdownMenuProps} from "react-bootstrap/DropdownMenu";

export default class AccountMenuDropdownMenu extends React.Component<HTMLAttributes<DropdownMenu> & DropdownMenuProps> {
  render() {
    const {
      children,
      className,
      'aria-labelledby': labeledBy,
    } = this.props;

    return (
      <div className={className} aria-labelledby={labeledBy}>
        {children}
      </div>
    )
  }
}