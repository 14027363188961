import React, {Dispatch, Reducer, ReducerAction} from 'react';
import _isEmpty from 'lodash/isEmpty';

import {useDataUtils} from "../../support/player";

import {Action, State} from "./types";
import appStateReducer from "./app-state-reducer";
import appStateDefaults from './app-state-defaults';

export default (): [State, Dispatch<ReducerAction<Reducer<State, Action>>>] => {
  const {fetch} = useDataUtils();
  const [appState, dispatch] = React.useReducer(appStateReducer, appStateDefaults);
  React.useEffect(() => {
    if (appState.player.isAuthenticated && _isEmpty(appState.player.data)) {
      fetch().then(player => dispatch({
        type: 'UPDATE_PLAYER_DATA',
        payload: player.data
      }));
    }
  }, [appState, fetch]);

  return [appState, dispatch];
}