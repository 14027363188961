import {useContext} from "react";

import AppContext from "../../../utils/app-state/app-context";

import {LoggedInPlayerData} from "../types";

import * as services from '../services';

export default () => {
  const {dispatchAppState} = useContext(AppContext);
  const {loadData, updateData} = services;

  const updateState = (data: LoggedInPlayerData) => dispatchAppState({
    "type": "UPDATE_PLAYER_DATA",
    "payload": data
  });

  const update = async (data: LoggedInPlayerData) => {
    await updateData(data);

    updateState(data);
  };

  const reload = async () => {
    const player = await loadData();

    updateState(player.data);
  };

  return {update, reload, fetch: loadData};
};