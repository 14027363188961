import React, {useEffect, useState, useContext} from 'react';
import {Field, Form, Formik, FormikProps, FormikValues} from "formik";
import _map from 'lodash/map';

import Nav from "react-bootstrap/Nav";

import {DEPOSIT_MIN_VALUE} from "../../../../../constants";
import {useTranslation, useFormSubmit} from "../../../../../utils/hooks";

import {useValidationSchema} from '../support/hooks';
import generatePaymentLink from '../support/generate-payment-link';

import cardsLogo from '../../../../../assets/images/logos/payment/visa-mc-maestro.png';
import skrillLogo from '../../../../../assets/images/logos/payment/skrill.png';
import netellerLogo from '../../../../../assets/images/logos/payment/neteller.png';
import bitcoinLogo from '../../../../../assets/images/logos/payment/bitcoin.png';
import paysafeCardLogo from '../../../../../assets/images/logos/payment/paysafecard.png';
import rapidTransferLogo from '../../../../../assets/images/logos/payment/rapid_transfer.png';

import FormError from '../../../../Errors/FormError';
import FormFieldError from '../../../../Errors/FormFieldError';

import AppContext from "../../../../../utils/app-state/app-context";

interface DepositData {
  amount: number;
}

const depositOptions = {
  paytriot: cardsLogo,
  skrill: skrillLogo,
  neteller: netellerLogo,
  bitcoin: bitcoinLogo,
  paysafecard: paysafeCardLogo,
  rapidTransfer: rapidTransferLogo
};

export default () => {
  const validationSchema = useValidationSchema();
  const {t, currentLanguage} = useTranslation('deposit');
  const [paymentLink, setPaymentLink] = useState();
  const [depositOption, setDepositOption] = useState('paytriot');
  const [submitState, onSubmit] = useFormSubmit(async (values: FormikValues) => {
    const response = await generatePaymentLink(
      depositOption,
      values.amount,
      currentLanguage
    );

    setPaymentLink(response.data.link);
  });
  useEffect(() => {
    if (paymentLink) {
      // redirect to payment page
      window.location.href = paymentLink;
    }
  }, [paymentLink]);

  const { dispatchAppState} = useContext(AppContext);


  return (
    <>
      <Nav
        variant="pills"
        className="flex-column flex-lg-row deposit-options"
        defaultActiveKey={depositOption}
        onSelect={(eventKey: string) => setDepositOption(eventKey)}
      >
        {_map(depositOptions, (logo: string, option: string) => (
          <Nav.Item key={option} className={`flex-lg-fill mb-3 text-center deposit-option-${option}`}>
            <Nav.Link eventKey={option}>
              <img src={logo} alt={option} />
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={{
          amount: DEPOSIT_MIN_VALUE
        }}
        render={(formikBag: FormikProps<DepositData>) => {
          const { isSubmitting } = formikBag;

          return (
            <Form>
              {submitState.kind === 'failed' && <FormError />}
              <div className="form-group">
                <label className="text-white" htmlFor="amount">
                  {t('form.fields.amount.label', 'Amount to deposit')}
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon2">€</span>
                  </div>
                  <Field
                    className="form-control-gold form-control"
                    type="number"
                    name="amount"
                    id="amount"
                    step="1"
                  />
                </div>
                <FormFieldError name="amount" />
                {/* <button
                  type="submit"
                  className="btn btn-outline-gold font-weight-bold btn-lg btn-block mt-4"
                  disabled={isSubmitting}
                >
                  {t('initReset.form.submit', 'Submit')}
                </button> */}
                <button
                  className="btn btn-outline-gold font-weight-bold btn-lg btn-block mt-4"
                  onClick={() => {
                    dispatchAppState({ type: 'HIDE_DEPOSIT_MODAL' })
                    dispatchAppState({ type: 'SHOW_TEMPORARILY_UNAVAILABLE_MODAL' })
                  }
                  }
                >
                  {t('initReset.form.submit', 'Submit')}
                </button>

              </div>
            </Form>
          );
        }}
      />
    </>
  )
};