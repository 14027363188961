import {useContext} from "react";

import AppContext from "../../../utils/app-state/app-context";

import {
  PlayerLoginDetails,
  LoggedInPlayerData,
  AnonymousPlayerDetails
} from "../types";
import * as services from '../services';

export default () => {
  const {dispatchAppState} = useContext(AppContext);

  const dispatchAuthenticatedPlayer = (player: LoggedInPlayerData) => dispatchAppState({
    type: 'SET_AUTHENTICATED_PLAYER',
    payload: player
  });

  const register = async (playerDetails: AnonymousPlayerDetails) => {
    const player = await services.register(playerDetails);

    dispatchAuthenticatedPlayer(player.data);
  };
  const login = async (loginDetails: PlayerLoginDetails) => {
    const player = await services.login(loginDetails);

    dispatchAuthenticatedPlayer(player.data);
  };
  const logout = async () => {
    await services.logout();

    dispatchAppState({type: 'SET_UNAUTHENTICATED_PLAYER'});
  };

  return { register, login, logout };
};