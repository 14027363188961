import {State, Action} from './types';

export default (
  state: State,
  action: Action
): State => {
  switch (action.type) {
    case "SET_AUTHENTICATED_PLAYER":
      return {
        ...state,
        player: {
          isAuthenticated: true,
          data: {
            ...action.payload
          }
        }
      };
    case "UPDATE_PLAYER_DATA":
      return {
        ...state,
        player: {
          ...state.player,
          data: {
            ...state.player.data,
            ...action.payload
          }
        }
      };
    case "SET_UNAUTHENTICATED_PLAYER":
      return {
        ...state,
        player: {
          isAuthenticated: false,
          data: {}
        }
      };
    case 'SHOW_DEPOSIT_MODAL':
      return {
        ...state,
        depositModalOpened: true
      };
    case 'HIDE_DEPOSIT_MODAL':
      return {
        ...state,
        depositModalOpened: false
      };
    case 'SHOW_TEMPORARILY_UNAVAILABLE_MODAL':
      return {
        ...state,
        temporarilyUnavailableModalOpened: true
      };
    case 'HIDE_TEMPORARILY_UNAVAILABLE_MODAL':
      return {
        ...state,
        temporarilyUnavailableModalOpened: false
      };
    default:
      return state;
  }
};