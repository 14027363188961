import React from 'react';
import {Link, NavLink} from "react-router-dom";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import AppContext from "../../../../utils/app-state/app-context";
import {useTranslation} from "../../../../utils/hooks";

import LanguageSwitcher from "../../../LanguageSwitcher";
import ProfileWidget from "../../../ProfileWidget";
import Navigation from "../Navigation";

import './SiteHeader.css';

const CollapsibleMenu = () => {
  const {t} = useTranslation();

  return (
    <Navbar.Collapse id="nav-content">
      <Nav>
        <Nav.Link className="mr-2 font-weight-bold" as={NavLink} to="/about">
          {t('navigation.links.about', 'About')}
        </Nav.Link>
        <Nav.Link className="mr-2 font-weight-bold" as={NavLink} to="/skins">
          {t('navigation.links.skins', 'Skins')}
        </Nav.Link>
        <Nav.Link className="mr-2 font-weight-bold" as={NavLink} to="/how-to-play">
          {t('navigation.links.howToPlay', 'How to play')}
        </Nav.Link>
        <Nav.Link className="mr-2 font-weight-bold" as={NavLink} to="/platforms">
          {t('navigation.links.platforms', 'Platforms')}
        </Nav.Link>
        <Nav.Link className="mr-1 font-weight-bold" as={NavLink} to="/contact-us">
          {t('navigation.links.contactUs', 'Contact us')}
        </Nav.Link>
        <LanguageSwitcher placement="mobile-menu" />
      </Nav>
    </Navbar.Collapse>
  );
};

const RightContent = () => (
  <div className="d-flex align-items-center">
    <PlayerActions />
    <LanguageSwitcher placement="header" />
  </div>
);

const PlayerActions = () => {
  const {t} = useTranslation();
  const {player} = React.useContext(AppContext);

  if (player.isAuthenticated) {
    return (<ProfileWidget placement="navbar" />);
  }

  return (
    <div>
      <Link
        className="btn btn-outline-gold mr-lg-2 ml-lg-2 font-weight-bold button--desktop login-button"
        to="/login"
        id="loginLink"
      >
        {t('navigation.links.login', 'Login')}
      </Link>
    </div>
  );
};

export default () => (
  <div className="site-header">
    <Navigation
      collapseOnSelect
      expand="lg"
      variant="dark"
      bg="faded"
      left={<CollapsibleMenu />}
      right={<RightContent />}
    />
  </div>
);