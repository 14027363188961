import React from 'react';
import { Link } from "react-router-dom";
import {faDice} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {formatMoney} from '../../../support/helpers';

import userImage from "../../../assets/images/userimage.png";
import './ProfileWidgetPage.css';

import {CASHOUT_MIN_VALUE} from "../../../constants";
import AppContext from "../../../utils/app-state/app-context";
import {useTranslation} from "../../../utils/hooks";

import GameLink from '../../GameLink';

const ProfileWidgetPage: React.FC = () => {
  const {t} = useTranslation('profile');
  const {player: {
    data: {
      firstName,
      lastName,
      balance = 0,
      virtualBalance = 0,
      frozen = 0
    }
  }, dispatchAppState} = React.useContext(AppContext);

  const showModal = (): void => {
    dispatchAppState({ type: 'SHOW_DEPOSIT_MODAL' });
  };

  const cashoutEnabled = balance >= CASHOUT_MIN_VALUE;

  return (
    <div className="profile-widget">
      <div className="d-flex flex-wrap">
        <div className="col-md-6 col-sm-6 col d-flex flex-row-reverse align-items-start widget-image">
          <img src={userImage} className="imageUser" alt="Alternate Text" />
        </div>
        <div className="col-md-6 col-sm-6 col d-flex flex-column widget-content">
          <div>
            <h5 className="mb-3 font-weight-bold text-gold">{firstName} {lastName}</h5>
            <p className="text-uppercase mb-1 text-white-barbud">
              {t('playerWidget.balance.available', 'balance: € {{balance}}', {balance: formatMoney(balance)})}
            </p>
            <p className="text-uppercase mb-1 text-white-barbud">
              {t('playerWidget.balance.virtual', 'virtual: € {{balance}}', {balance: formatMoney(virtualBalance)})}
            </p>
            <p className="text-uppercase text-white-barbud">
              {t('playerWidget.balance.frozen', 'frozen: € {{frozen}}', {frozen: formatMoney(frozen)})}
            </p>
            <div className="form-group">
              <button
                type="button"
                className="btn btn-outline-gold font-weight-bold"
                onClick={showModal}
              >
                {t('playerWidget.buttons.deposit', 'Deposit')}
              </button>
              &nbsp;
              <Link
                to="/billing/cashout"
                className={`btn btn-outline-gold font-weight-bold${!cashoutEnabled ? ' disabled' : ''}`}
              >
                {t('playerWidget.buttons.cashout', 'Cashout')}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <GameLink
          className="btn btn-play btn-outline-gold font-weight-bold"
          to="/game"
        >
          <FontAwesomeIcon className="play-icon" icon={faDice} size="1x" />&nbsp;&nbsp;
          {t('layout:navigation.links.playNow', 'Play now')}
        </GameLink>
      </div>
    </div>
  )
};

export default ProfileWidgetPage;