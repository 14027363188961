import React, {lazy} from 'react';
import {Route, Switch} from 'react-router-dom';

import PrivateRoute from '../../PrivateRoute';

// sync routes
import Home from '../../../routes/ContentPages/Home';

// Async content page routes
const ResponsibleGaming = lazy(() => import('../../../routes/ContentPages/ResponsibleGaming'));
const PaymentMethods = lazy(() => import('../../../routes/ContentPages/PaymentMethods'));
const HowToPlay = lazy(() => import('../../../routes/ContentPages/HowToPlay'));
const CashoutPolicy = lazy(() => import('../../../routes/ContentPages/CashoutPolicy'));
const HelpDeposit = lazy(() => import('../../../routes/ContentPages/HelpDeposit'));
const SecurityAndPrivacy = lazy(() => import('../../../routes/ContentPages/SecurityAndPrivacy'));
const Faq = lazy(() => import('../../../routes/ContentPages/Faq'));
const TermsOfService = lazy(() => import('../../../routes/ContentPages/TermsOfService'));

// Async billing routes
const CashoutSuccess = lazy(() => import('../../../routes/Billing/Cashout/CashoutSuccess'));
const Cashout = lazy(() => import('../../../routes/Billing/Cashout'));
const SkrillDepositSuccess = lazy(() => import('../../../routes/Billing/Deposit/SkrillDeposit/SkrillDepositSuccess'));
const SkrillDepositCancel = lazy(() => import('../../../routes/Billing/Deposit/SkrillDeposit/SkrillDepositCancel'));
const PaytriotDepositSuccess = lazy(() => import('../../../routes/Billing/Deposit/PaytriotDeposit/PaytriotDepositSuccess'));
const PaytriotDepositCancel = lazy(() => import('../../../routes/Billing/Deposit/PaytriotDeposit/PaytriotDepositCancel'));

// Async auth routes
const Login = lazy(() => import('../../../routes/Auth/Login'));
const SignUp = lazy(() => import('../../../routes/Auth/SignUp'));
const ResetPasswordInit = lazy(() => import('../../../routes/Auth/ResetPassword/ResetPasswordInit'));
const ResetPasswordComplete = lazy(() => import('../../../routes/Auth/ResetPassword/ResetPasswordComplete'));
const ResetPasswordSuccess = lazy(() => import('../../../routes/Auth/ResetPassword/ResetPasswordSuccess'));

// Async misc routes
const Game = lazy(() => import('../../../routes/Game'));
const ContactUs = lazy(() => import('../../../routes/ContactUs'));
const Profile = lazy(() => import('../../../routes/Profile'));
const NotFound = lazy(() => import('../../../routes/NotFound'));

const Content: React.FC = () => (
  <div className="container mt-4" id="renderBody">
    <Switch>
      {/* Public routes */}
      <Route exact path="/" component={Home} />
      <Route path="/about" render={() => <Home scrollToAnchor="about-game" />} />
      <Route path="/skins" render={() => <Home scrollToAnchor="skins" />} />
      <Route path="/platforms" render={() => <Home scrollToAnchor="platforms" />} />
      <Route path="/responsible-gaming" component={ResponsibleGaming} />
      <Route path="/payment-methods" component={PaymentMethods} />
      <Route path="/how-to-play" component={HowToPlay} />
      <Route path="/cashout" component={CashoutPolicy} />
      <Route path="/deposit" component={HelpDeposit} />
      <Route path="/security-and-privacy" component={SecurityAndPrivacy} />
      <Route path="/faq" component={Faq} />
      <Route path="/terms-of-service" component={TermsOfService} />
      <Route path="/game" component={Game} />
      <Route path="/contact-us" component={ContactUs} />
      <Route path="/login" component={Login} />
      <Route path="/sign-up" component={SignUp} />
      <Route path="/password/reset/init" component={ResetPasswordInit} />
      <Route path="/password/reset/complete/:token" render={routeProps => <ResetPasswordComplete {...routeProps} />}/>
      <Route path="/password/reset/success" component={ResetPasswordSuccess} />

      {/* Private routes */}
      <PrivateRoute path="/profile" component={Profile} />
      <PrivateRoute path="/billing/deposit/skrill/success" component={SkrillDepositSuccess} />
      <PrivateRoute path="/billing/deposit/skrill/cancel" component={SkrillDepositCancel} />
      <PrivateRoute path="/billing/deposit/paytriot/success" component={PaytriotDepositSuccess} />
      <PrivateRoute path="/billing/deposit/paytriot/cancel" component={PaytriotDepositCancel} />
      <PrivateRoute path="/billing/cashout/success" component={CashoutSuccess} />
      <PrivateRoute path="/billing/cashout" component={Cashout} />

      {/* Catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  </div>
);

export default Content;