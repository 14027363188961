import React from 'react';

import logo from '../../assets/images/logos/barbudi_logo.png';

interface LogoProps {
  className?: string
}

const Logo: React.FC<LogoProps> = ({className = ''}) => {
  const logoClassName = `logo ${className}`;

  return (
    <img src={logo} className={logoClassName} alt="logo" />
  );
};

export default Logo;