import React, {useState, useEffect} from 'react';
import ReactHtmlParser, {Transform} from 'react-html-parser';
import _isUndefined from 'lodash/isUndefined';

import API from '../../utils/api';
import {useTranslation} from '../../utils/hooks';

const ContentPage: React.FC<{name: string, transform?: Transform, onPageLoaded?: () => void}> = (props) => {
  const [content, setContent] = useState('');
  const {currentLanguage} = useTranslation();

  const {name, transform, onPageLoaded} = props;

  useEffect(() => {
    API
      .get(`translations/${currentLanguage}/content-pages/${name}`)
      .then(result => setContent(result.data));
  }, [name, currentLanguage]);

  useEffect(() => {
    if (content && !_isUndefined(onPageLoaded)) {
      onPageLoaded();
    }
  }, [content, onPageLoaded]);

  return (
    <div className={`content-page ${name}`}>
      { ReactHtmlParser(content, {transform}) }
    </div>
  );
};

export default ContentPage;