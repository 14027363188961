import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import {useTranslation} from "../../../utils/hooks";

import './LanguageSwitcherWithHeaderPlacement.css';

const LanguageSwitcherDesktop: React.FC = () => {
  const {currentLanguage, supportedLanguages, switchLanguage} = useTranslation();

  return (
    <Dropdown className="nav-item navRight desktop-lang">
      <Dropdown.Toggle id="dropdown-basic" className="btn btn-outline-gold text-uppercase button--desktop font-weight-bold">
        {currentLanguage}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {supportedLanguages.map((lang: string) =>
          <Dropdown.Item
            key={lang}
            className="dropdown-item bg-dark-custom font-weight-bold text-capitalize"
            onClick={() => switchLanguage(lang)}
          >
            {lang}
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSwitcherDesktop;