import {useState} from "react";
import {FormikActions, FormikValues} from "formik";
import {AxiosResponse} from "axios";

import {SubmitState} from "../../types/form";

export default (
  submitLogic: (values: FormikValues) => void | Promise<void> | AxiosResponse<any>
): [
  SubmitState,
  (values: FormikValues, actions: FormikActions<FormikValues>) => void
] => {
  const [submitState, setSubmitState] = useState<SubmitState>({kind: "awaiting"});
  const executeSubmit = async (
    values: FormikValues,
    actions: FormikActions<FormikValues>
  ) => {
    try {
      await submitLogic(values); // execute submit logic

      setSubmitState({kind: "submitted"});
    } catch (e) {
      setSubmitState({
        kind: "failed",
        error: e
      });
    } finally {
      actions.setSubmitting(false); // activate submit button
    }
  };

  return [submitState, executeSubmit];
};