import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import XHR from 'i18next-xhr-backend'; // fallback xhr load
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache

import {APP_LANGUAGES} from "../constants";

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    load: 'languageOnly',
    ns: ['layout'],
    defaultNS: 'layout',
    whitelist: APP_LANGUAGES,
    backend: {
      backends: [
        LocalStorageBackend,  // primary
        XHR                   // fallback
      ],
      backendOptions: [{
        /* use defaults */
      }, {
        loadPath: '/api/translations/{{lng}}/{{ns}}' // xhr load path for my own fallback
      }]
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      wait: true
    }
  });


export default i18n;