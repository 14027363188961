import React from 'react';
import {useTranslation} from "react-i18next";

import './FooterLinks.css';

import AppValidationLink from './AppValidationLink';
import FooterLink from './FooterLink';

const FooterLinks: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="row col-md-12 text-center bd-footer-links">
      <div className="col-md-3">
        <FooterLink href="/about">{t('navigation.links.about', 'About the game')}</FooterLink><br/>
        <FooterLink href="/responsible-gaming">{t('navigation.links.responsibleGaming', 'Responsible Gaming')}</FooterLink><br/>
        <FooterLink href="/payment-methods">{t('navigation.links.paymentMethods', 'Payment Methods')}</FooterLink>
      </div>
      <div className="col-md-3">
        <FooterLink href="/how-to-play">{t('navigation.links.howToPlay', 'How to play')}</FooterLink><br/>
        <FooterLink href="/cashout">{t('navigation.links.cashout', 'Cashout')}</FooterLink><br/>
        <FooterLink href="/deposit">{t('navigation.links.deposit', 'Deposit')}</FooterLink>
      </div>
      <div className="col-md-3 mb-2 p-0">
        <FooterLink href="/contact-us">{t('navigation.links.contactUs', 'Contact us')}</FooterLink><br/>
        <FooterLink href="/security-and-privacy">{t('navigation.links.securityAndPrivacy', 'Security and Privacy')}</FooterLink><br/>
        <FooterLink href="/faq">{t('navigation.links.faq', 'F.A.Q.')}</FooterLink><br />
        <FooterLink href="/terms-of-service">{t('navigation.links.termsOfService', 'Terms of Service')}</FooterLink>
      </div>
      <div className="col-md-3 align-content-center" id="eGamingDiv">
        {/* <AppValidationLink /> */}
      </div>
    </div>
  );
};

export default FooterLinks;