import {convertNodeToElement, Transform} from "react-html-parser";
import _isUndefined from "lodash/isUndefined";

export default (imageSources: {[s: string]: string}): Transform => {
  const transform: Transform = (node, index) => {
    if (node.type === 'tag' && node.name === 'img' && !_isUndefined(node.attribs)) {
      const realImageSource = imageSources[node.attribs.src];
      if (realImageSource) {
        node.attribs.src = realImageSource;

        return convertNodeToElement(node, index, transform);
      }
    }
  };

  return transform;
}