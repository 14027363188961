let supportedLanguages: string[] = [];

const supportedLanguageStr = process.env.REACT_APP_SUPPORTED_LANGUAGES;
if (supportedLanguageStr) {
  supportedLanguages = supportedLanguageStr.split(',');
}

export const APP_LANGUAGES = supportedLanguages;
export const GENDERS = ['male', 'female'];

export const DEPOSIT_MIN_VALUE = 10;

export const CASHOUT_OPTIONS = ['skrill', 'bank_transfer'];
export const CASHOUT_SKRILL_MIN_VALUE = 100;
export const CASHOUT_SKRILL_MAX_VALUE = 10000;
export const CASHOUT_BANK_TRANSFER_MIN_VALUE = 5000;
export const CASHOUT_MIN_VALUE = CASHOUT_SKRILL_MIN_VALUE;

export const PLAY_MARKET_URL = 'https://play.google.com/store/apps/details?id=com.barbudi.www';