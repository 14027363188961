import React from 'react';
import {withRouter, RouteComponentProps} from "react-router";

class ScrollToTop extends React.Component<RouteComponentProps> {
  componentDidUpdate(prevProps: Readonly<RouteComponentProps>): void {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);