import React from 'react';
import {
  LanguageSwitcherProps,
  PlacementSpecificSwitchers
} from "./support/types";

import LanguageSwitcherWithHeaderPlacement from './LanguageSwitcherWithHeaderPlacement';
import LanguageSwitcherWithMobileMenuPlacement from './LanguageSwitcherWithMobileMenuPlacement';
import LanguageSwitcherWithGameMenuPlacement from './LanguageSwitcherWithGameMenuPlacement';

const placementSpecificVersion = (): PlacementSpecificSwitchers => ({
  header: <LanguageSwitcherWithHeaderPlacement />,
  "mobile-menu": <LanguageSwitcherWithMobileMenuPlacement />,
  "game-menu": <LanguageSwitcherWithGameMenuPlacement />
});
const LanguageSwitcher: React.FC<LanguageSwitcherProps> = (props) => placementSpecificVersion()[props.placement];

export default LanguageSwitcher;