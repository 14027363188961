import React, {useContext} from 'react';
import Modal from 'react-bootstrap/Modal';

import './DepositModal.css';

import AppContext from "../../../../utils/app-state/app-context";
import {useTranslation} from '../../../../utils/hooks';

import DepositForm from './DepositForm';

export default () => {
  const {t} = useTranslation('deposit');
  const {depositModalOpened, dispatchAppState} = useContext(AppContext);
  const closeModal = (): void => {
    dispatchAppState({ type: 'HIDE_DEPOSIT_MODAL' });
  };

  return (
    <Modal
      show={depositModalOpened}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-dialog-gold deposit-modal-dialog"
      size="lg"
      centered
    >
      <Modal.Header className="border-0 justify-content-center pb-3" closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          as="h5"
          className="text-uppercase text-center font-weight-bold color-gold"
        >
          {t('deposit:modal.title', 'Deposit')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="border-0 pt-0 font-weight-bold color-gold">
        <hr className="mb-4" />
        <span>
          {t('deposit:modal.depositMethodTitle', 'Choose deposit method')}
        </span>
        <DepositForm />
      </Modal.Body>
    </Modal>
  );
};