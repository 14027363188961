import React from 'react';

import './AccountMenuDropdown.css';

export default class AccountMenuDropdown extends React.Component {
  render() {
    return (
      <ul className="account-menu">
        <li className="nav-itemDrop dropdown p-2">
          {this.props.children}
        </li>
      </ul>
    )
  }
}