import React from 'react';

import {useTranslation} from "../../../utils/hooks";

import './LanguageSwitcherWithMobileMenuPlacement.css';

const LanguageSwitcherWithMobileMenuPlacement: React.FC = () => {
  const {currentLanguage, supportedLanguages, switchLanguage} = useTranslation();

  return (
    <div className="mobile-lang">
      <div className="btn-group" role="group">
        {supportedLanguages.map(lang => (
          <button
            key={lang}
            className={`btn btn-lng nav-link font-weight-bold text-capitalize${ lang === currentLanguage ? ' active' : ''}`}
            onClick={() => switchLanguage(lang)}
          >
            {lang}
          </button>
        ))}
      </div>
    </div>
  );
};

export default LanguageSwitcherWithMobileMenuPlacement;
