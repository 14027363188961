import React from 'react';

import ProfileWidgetNavbar from "./ProfileWidgetNavbar";
import ProfileWidgetPage from "./ProfileWidgetPage";

const ProfileWidget: React.FC<{
  placement?: 'navbar' | 'page'
}> = ({placement = 'page'}) => placement === 'navbar'
  ? <ProfileWidgetNavbar />
  : <ProfileWidgetPage />;

export default ProfileWidget;