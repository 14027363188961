import {createContext, Dispatch, ReducerAction, Reducer} from 'react';
import {State, Action} from './types';

import appStateDefaults from "./app-state-defaults";

export type AppContextType = State & {
  dispatchAppState: Dispatch<ReducerAction<Reducer<State, Action>>>
};

export default createContext<AppContextType>({
  ...appStateDefaults,
  dispatchAppState: () => {}
});