import React from 'react';

import userImage from "../../../../../../assets/images/userimage.png";
import './AccountMenuDropdownToggle.css';

import {DropdownToggleProps} from "react-bootstrap/DropdownToggle";
import {DropdownItemProps} from "react-bootstrap/DropdownItem";

export default class AccountMenuDropdownToggle extends React.Component<DropdownToggleProps & DropdownItemProps> {
  render() {
    return (
      <img
        className="user-account nav-linkDropMenu dropdown-toggle m-0"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        src={userImage}
        width="25"
        height="25"
        alt="Open account menu"
        onClick={this.props.onClick}
      />
    )
  }
}