import React from 'react';
import {useTranslation} from "react-i18next";

import './ProfileWidgetNavbar.css';

import {formatMoney} from "../../../support/helpers";

import AppContext from "../../../utils/app-state/app-context";
import AccountMenu from "./AccountMenu";

const ProfileWidgetNavbar: React.FC = () => {
  const {t} = useTranslation();
  const {player, dispatchAppState} = React.useContext(AppContext);

  const showModal = (): void => {
    dispatchAppState({ type: 'SHOW_DEPOSIT_MODAL' });
  };

  return (
    <>
      <AccountMenu />
      <ul className="bank">
        <li className="text-gold font-weight-bold">{player.data.firstName} {player.data.lastName}</li>
        <li className="userIfo" id="balance">€ {formatMoney(player.data.balance || 0)}</li>
      </ul>
      <button
        type="button"
        className="btn btn-outline-gold mr-sm-1 mr-md-2 font-weight-bold button--desktop deposit-btn"
        onClick={showModal}
      >
        {t('navigation.links.deposit', 'Deposit')}
      </button>
    </>
  );
};

export default ProfileWidgetNavbar;