import {format} from 'date-fns/fp';
import * as _ from 'lodash/fp';

const extractDateParts = _.split('-');
const makeDatePartsNumeric = _.map(parseInt);
const dateFromParts = (parts: number[]) => {
  const [year, month, day] = parts;

  return new Date(year, month - 1, day);
};

// converts dob from string -> Date representation
const dobStringToDate = _.pipe(extractDateParts, makeDatePartsNumeric, dateFromParts);
// format dob Date instance to new string format
const dobDateToNewStringFormat = format('MMMM/dd/yyyy');
// final dob reformatting function
const formatDob = _.pipe(dobStringToDate, dobDateToNewStringFormat);

export default formatDob;