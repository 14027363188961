import React from 'react';

import {useTranslation} from "../../../utils/hooks";

const LanguageSwitcherWithGameMenuPlacement: React.FC = () => {
  const {currentLanguage, supportedLanguages, switchLanguage} = useTranslation();

  return (
    <div className="btn-group" role="group">
      {supportedLanguages.map(lang =>
        <button
          key={lang}
          className={`btn btn-link nav-link mr-2 font-weight-bold text-capitalize${ lang === currentLanguage ? ' active' : ''}`}
          onClick={() => switchLanguage(lang)}
        >
          {lang}
        </button>
      )}
    </div>
  );
};

export default LanguageSwitcherWithGameMenuPlacement;

