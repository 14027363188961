import React, {lazy} from 'react';
import {Route, RouteProps} from "react-router";

import AppContext from "../../utils/app-state/app-context";

const Login = lazy(() => import('../../routes/Auth/Login'));

export default (props: Pick<RouteProps, 'path' | 'component'>) => {
  const {player}  = React.useContext(AppContext);
  const Component = props.component as React.FC<RouteProps>;

  return (
    <Route
      path={props.path}
      render={routeProps => player.isAuthenticated ? <Component {...routeProps} /> : <Login />}
    />
  );
};