import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';

import AppContext from "../../../../utils/app-state/app-context";
import { useTranslation } from '../../../../utils/hooks';

export default () => {
  const {t} = useTranslation();
  const {temporarilyUnavailableModalOpened, dispatchAppState} = useContext(AppContext);
  const closeModal = (): void => {
    dispatchAppState({ type: 'HIDE_TEMPORARILY_UNAVAILABLE_MODAL' });
  };

  return (
    <Modal
      show={temporarilyUnavailableModalOpened}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-dialog-gold"
      size="lg"
      centered
    >
      <Modal.Header className="border-0 justify-content-center pb-3" closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          as="h5"
          className="text-uppercase text-center font-weight-bold color-gold"
        >
          {t('temporarily_unavailable.header', 'Warning!')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="border-0 pt-0 font-weight-bold color-gold">
        <hr className="mb-4" />
        <span>
          {t('temporarily_unavailable.body', 'Pay service temporarily unavailable for your region.')}
        </span>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: 0 }}>
        {/* <button 
          onClick={() => setForwardToSignupPageFlag(true)}
          className="btn btn-outline-gold font-weight-bold btn-lg btn-block active"
        >
          {t('signup_now_popup.button', 'Sign up')}
        </button> */}
      </Modal.Footer>
    </Modal>
  );
};