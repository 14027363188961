import React from 'react';

import './Copyright.css';

const PaymentMethods: React.FC = () => {
  return (
    <div className="text-center font-weight-bold text-white-barbud pt-3 text-gold copyright">
      Copyright! © 2024, Barbut.md. All rights reserved. <br />
      Site operated, fully licensed and owned by Barbooth B.V.
      Pareraweg 45, Willemstad, Curacao. Registration number 149102. Online gambling is regulated in Curacao,
      Netherlands Antilles.<br />This Service operates under the License No. 1668/JAZ issued to Curaçao eGaming,
      Authorized and Regulated by the Government of Curacao.
    </div>
  );
};

export default PaymentMethods;
