import React, {useEffect, useState} from 'react';
import {processNodes, Transform} from "react-html-parser";

import './Home.css';

import {useTitle, useTranslation} from "../../../utils/hooks";
import createImageSourceTransform from '../../../utils/html-parser/image-sorce-transform';
import ContentPage from '../../../components/ContentPage';
import GameLink from '../../../components/GameLink';

// mobile stores
import apple from '../../../assets/images/apple.png';
import google from '../../../assets/images/google.png';
// skins
import arabianSkinLogo from '../../../assets/images/logos/skins/arabian_skin_log-min.png';
import classicSkinLogo from '../../../assets/images/logos/skins/classic_skin_logo.png';
import spaceSkinLogo from '../../../assets/images/logos/skins/space_skin_logo.png';
// misc
import aboutGameLogo from '../../../assets/images/logos/about_game_logo-min.png';
import howToPlayLogo from '../../../assets/images/logos/how_to_play_logo.png';
import mobileLogo from '../../../assets/images/logos/mobile_logo.png';

const createTransform = () => {
  const imageSourceTransform = createImageSourceTransform({
    apple,
    google,
    aboutGameLogo,
    arabianSkinLogo,
    classicSkinLogo,
    spaceSkinLogo,
    howToPlayLogo,
    mobileLogo
  });

  const transform: Transform = (node, index) => {
    if (node.type === 'tag' && node.name === 'gamelinkbutton') {
      return (
        <GameLink
          key={index}
          className="btn btn-outline-gold font-weight-bold menuBtn"
          to="/game"
        >
          {processNodes(node.children || [], transform)}
        </GameLink>
      );
    }

    return imageSourceTransform(node, index);
  };

  return transform;
};

export default (props: {scrollToAnchor?: 'about-game' | 'skins' | 'platforms'}) => {
  const {t} = useTranslation('layout');
  const [titleByAnchor, setTitleByAnchor] = useState();

  useEffect(() => {
    if (props.scrollToAnchor) {
      switch (props.scrollToAnchor) {
        case "about-game":
          setTitleByAnchor(t('navigation.links.about', 'About the game'));
          break;

        case "skins":
          setTitleByAnchor(t('navigation.links.skins', 'Skins'));
          break;

        default:
          setTitleByAnchor(t('navigation.links.platforms', 'Platforms'));
      }
    }
  }, [props, t]);

  useTitle(titleByAnchor);

  return (
    <ContentPage
      name="home"
      transform={createTransform()}
      onPageLoaded={() => {
        if (props.scrollToAnchor) {
          const el = document.getElementsByClassName(`js-anchor-${props.scrollToAnchor}`)[0];
          if (el) {
            el.scrollIntoView();
          }
        }
      }}
    />
  );
}
