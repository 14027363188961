import React from 'react';
import {NavLink} from "react-router-dom";

interface FooterLinkProps {
  href: string
}

const FooterLink: React.FC<FooterLinkProps> = ({href, children}) =>
  <NavLink exact className="font-weight-bold nav-link-footer" to={href}>
    {children}
  </NavLink>;

export default FooterLink;