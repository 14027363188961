import React, {SyntheticEvent} from 'react';
import {Link, LinkProps} from "react-router-dom";

import {PLAY_MARKET_URL} from "../../constants";
import {mobile} from "../../support/helpers";

const processProps = (props: LinkProps): LinkProps => {
  if (mobile.mobile()) {
    return  {
      ...props,
      onClick: (e: SyntheticEvent) => {
        e.preventDefault();

        if (mobile.os() === 'AndroidOS') {
          window.location.href = PLAY_MARKET_URL;
        } else {
          alert('For a better user experience please try our game it in your desktop’s browser');
        }
      }
    };
  }

  return props;
};

export default (props: LinkProps) => {
  let {children, ...linkProps} = processProps(props);

  return (
    <Link {...linkProps}>{children}</Link>
  )
};