import React, {useState, useEffect, Suspense} from 'react';
import {BrowserRouter as Router} from "react-router-dom";

import AppContext from '../../utils/app-state/app-context';
import useAppState from '../../utils/app-state/app-state-hook';

import LoadingIndicator from '../LoadingIndicator';
import ScrollToTop from '../ScrollToTop';

import Header from './Header';
import Content from './Content';
import Footer from "./Footer";

const App: React.FC = () => {
  const [headerVariant, setHeaderVariant] = useState();
  const [appState, dispatchAppState] = useAppState();

  useEffect(() => {
    setHeaderVariant(window.location.pathname.match(/^\/game/) ? 'game' : 'site');
  }, []);

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <AppContext.Provider value={{...appState, dispatchAppState}}>
        <Router>
          <ScrollToTop />
          <Header variant={headerVariant} />
          <Content />
          <Footer />
        </Router>
      </AppContext.Provider>
    </Suspense>
  );
};

export default App;
