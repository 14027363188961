import React, {useState, useEffect, useContext} from 'react';
import { Redirect } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import AppContext from "../../../../utils/app-state/app-context";
import {useTranslation} from '../../../../utils/hooks';

const STATE_CACHE_KEY = 'signupNowModalOpened';
const cacheState = (state: boolean) => {
  window.sessionStorage.setItem(STATE_CACHE_KEY, '' + state);
};
const cachedState = () => JSON.parse('' + window.sessionStorage.getItem(STATE_CACHE_KEY));

const initialState = () => {
  const state = JSON.parse('' + cachedState());
  const stateDefined = state != null;

  return stateDefined ? state : true;
};

const isSignupPage = () => window.location.pathname.match(/^\/sign-up/) != null;

const useModalState = () => {
  const { player } = useContext(AppContext);
  const [opened, setOpened] = useState(!player.isAuthenticated && !isSignupPage() && initialState());

  useEffect(() => {
    cacheState(opened);
  }, [opened]);

  const closeModal = (): void => {
    setOpened(false);
  };

  return {opened, closeModal};
};

export default () => {
  const {t} = useTranslation();
  const {opened, closeModal} = useModalState();
  const [forwardToSignupPage, setForwardToSignupPageFlag] = useState(false);

  if (forwardToSignupPage) {
    return (<Redirect to="/sign-up" />);
  }

  return (
    <Modal
      show={opened}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-dialog-gold"
      size="lg"
      centered
    >
      <Modal.Header className="border-0 justify-content-center pb-3" closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          as="h5"
          className="text-uppercase text-center font-weight-bold color-gold"
        >
          {t('signup_now_popup.header', 'You\'re lucky!')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="border-0 pt-0 font-weight-bold color-gold">
        <hr className="mb-4" />
        <span>
          {t('signup_now_popup.body', 'Sign up now to get 1.000€ on your virtual money account to spend with other players! It\'s free!')}
        </span>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: 0 }}>
        <button 
          onClick={() => setForwardToSignupPageFlag(true)}
          className="btn btn-outline-gold font-weight-bold btn-lg btn-block active"
        >
          {t('signup_now_popup.button', 'Sign up')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};