import axios from 'axios';
import Cookie from 'js-cookie';

// configure base axios instance
const API = axios.create({
  baseURL: '/api/',
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
});

// Add a response interceptor
API.interceptors.response.use(
  (response) => response,
  (error) => {
      if (error.response && error.response.status === 401) {
        Cookie.remove('jwt_header_payload');
        window.location.reload();

        return;
      }

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
  }
);

export default API;