import {useEffect} from "react";
import i18next from "i18next";
import {Namespace, useTranslation} from "react-i18next";
import {APP_LANGUAGES} from "../../constants";

export default (ns?: Namespace): {
  currentLanguage: string,
  supportedLanguages: string[],
  switchLanguage: (lng: string) => Promise<i18next.TFunction>,
  t: (key: string, defaultValue: string, replacements?: i18next.StringMap) => string
} => {
  const {t, i18n} = useTranslation(ns);
  const currentLanguage = i18n.language.split('-')[0];

  // handle app language class
  useEffect(() => {
    const languageClass = `lng-${currentLanguage}`;
    // add language class
    document.body.classList.add(languageClass);
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr';
    // remove language class
    return () => {
      document.body.classList.remove(languageClass);
      document.body.dir = '';
    }
  }, [currentLanguage]);

  const updateLanguage = (lng: string) => i18n.changeLanguage(lng);
  const wrappedT = (
    key: string,
    defaultValue: string,
    replacements = {}
  ) => t(key, {
    ns,
    defaultValue,
    ...replacements
  });

  return {
    currentLanguage,
    supportedLanguages: APP_LANGUAGES,
    switchLanguage: updateLanguage,
    t: wrappedT
  };
};