import React, {useContext} from 'react';
import {Link} from "react-router-dom";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import AppContext from "../../../../utils/app-state/app-context";
import {useTranslation} from "../../../../utils/hooks";

import LanguageSwitcher from "../../../LanguageSwitcher";
import Navigation from '../Navigation';

import './GameHeader.css';

const CollapsibleMenu = () => {
  const {t} = useTranslation();

  return (
    <Navbar.Collapse id="nav-content" className="col-md-5">
      <Nav as="ul" className="p-0">
        <Nav.Item as="li" className="pb-2">
          <CollapsibleMenuAccountActions />
        </Nav.Item>
      </Nav>
      <Nav className="p-0" as="ul">
        <Nav.Item as="li">
          <Nav.Link className="nav-link font-weight-bold" id="aboutGame" as={Link} to="/#aboutGame">
            {t('navigation.links.about', 'About')}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link className="nav-link font-weight-bold" as={Link} to="/#skins">
            {t('navigation.links.skins', 'Skins')}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link className="nav-link font-weight-bold" as={Link} to="/how-to-play">
            {t('navigation.links.howToPlay', 'How to play')}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link className="nav-link font-weight-bold" as={Link} to="/#platform">
            {t('navigation.links.platforms', 'Platforms')}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link className="nav-link font-weight-bold last" as={Link} to="/contact-us">
            {t('navigation.links.contactUs', 'Contact us')}
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Nav className="p-0" as="ul">
        <Nav.Item as="li">
          <LanguageSwitcher placement="game-menu" />
        </Nav.Item>
      </Nav>
    </Navbar.Collapse>
  );
};

const CollapsibleMenuAccountActions = () => {
  const {t} = useTranslation();
  const {player} = useContext(AppContext);

  if (!player.isAuthenticated) {
    return (
      <>
        <Link className="btn btn-outline-gold font-weight-bold ml-1 mr-1" to="/login">
          {t('navigation.links.login', 'Login')}
        </Link>
        <Link className="btn btn-outline-gold font-weight-bold ml-1 mr-1" to="/sign-up">
          {t('navigation.links.signUp', 'Sign up')}
        </Link>
      </>
    );
  }

  return (
    <Link className="btn btn-outline-gold m-1 font-weight-bold" to="/profile/personal-details">
      {t('navigation.links.account', 'Account')}
    </Link>
  );
};

const RightContent = () => {
  const {t} = useTranslation();
  const {player, dispatchAppState} = useContext(AppContext);

  if (player.isAuthenticated) {
    return (
      <button
        type="button"
        className="btn btn-outline-gold mr-sm-1 mr-md-2 font-weight-bold button--desktop deposit-btn"
        onClick={() => dispatchAppState({ type: 'SHOW_DEPOSIT_MODAL' })}
      >
        {t('navigation.links.deposit', 'Deposit')}
      </button>
    );
  }

  return (
    <Link
      to="/login"
      className="btn btn-outline-gold font-weight-bold"
    >
      {t('navigation.links.playForReal', 'Play for real')}
    </Link>
  );
};

export default () => (
  <div className="game-header">
    <Navigation
      variant="dark"
      expand={false}
      left={<CollapsibleMenu />}
      right={<RightContent />}
    />
  </div>
);