import React from 'react';
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Navbar, {NavbarProps} from "react-bootstrap/Navbar";
import {NavbarCollapseProps} from "react-bootstrap/NavbarCollapse";

import {faBars} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Logo from '../../../Logo';

type NavigationProps = NavbarProps & {
  left: React.ReactElement<NavbarCollapseProps>,
  right: React.ReactElement
};

export default (props: NavigationProps) => {
  const {left, right, ...navbarProps} = props;

  return (
    <Navbar {...navbarProps}>
      <Container>
        <Navbar.Toggle aria-controls="nav-content">
          <span>
            <FontAwesomeIcon icon={faBars} />
          </span>
        </Navbar.Toggle>
        <Navbar.Brand as={Link} to="/">
          <Logo/>
        </Navbar.Brand>
        {left}
        {right}
      </Container>
    </Navbar>
  );
}