import API from "../../../utils/api";
import {format} from 'date-fns';
import {AnonymousPlayerDetails} from "../types";

export default async (playerDetails: AnonymousPlayerDetails) => await API.post('register/v2', {
  first_name: playerDetails.firstName,
  last_name: playerDetails.lastName,
  dob: format(+playerDetails.dob, 'yyyy-MM-dd'),
  gender: playerDetails.gender,
  country: playerDetails.country,
  city: playerDetails.city,
  address: playerDetails.address,
  zip: playerDetails.zip,
  mobile_phone: playerDetails.mobilePhone,
  email: playerDetails.email,
  password: playerDetails.password,
  password_confirmation: playerDetails.confirmPassword
});