import React, {useState, useEffect} from 'react';
import { faDice } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './LoadingIndicator.css';

const DEFAULT_DELAY = 500;

const LoadingIndicator: React.FC<{delay?: number}> = (props) => {
  const [ready, setReady] = useState(false);
  const {delay = DEFAULT_DELAY} = props;
  useEffect(() => {
    const timer = setTimeout(() => setReady(true), delay);
    return () => clearTimeout(timer);
  }, [delay]);

  return !ready ? null : (
    <div className="loading-indicator">
      <FontAwesomeIcon icon={faDice} size="6x" spin />
    </div>
  );
};

export default LoadingIndicator;