import * as Yup from 'yup';

import {DEPOSIT_MIN_VALUE} from "../../../../../../constants";
import {useTranslation} from "../../../../../../utils/hooks";

export default () => {
  const { t } = useTranslation('deposit');

  return Yup.object().shape({
    amount: Yup.number()
      .required(t(
        'form.fields.amount.errors.required',
        'Please enter deposit amount'
      ))
      .min(
        DEPOSIT_MIN_VALUE,
        t(
          'form.fields.amount.errors.min',
          'We expect deposit amount to be at least € {{minAmount}}',
          {minAmount: DEPOSIT_MIN_VALUE}
        )
      )
  });
}